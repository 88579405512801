
import { defineComponent, inject } from "vue";
import { useI18n } from "vue-i18n";
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { CheckUserAgent } from "@hd2/common/src/utils";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { AxiosStatic } from "axios";

export const ProfileComponent = defineComponent({
  components: {
    PlusCircleFilled,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const isSupported =
      !CheckUserAgent.isSafari() && !CheckUserAgent.isOperaMobile();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();

    const addCert = async () => {
      if (isSupported) {
        router.push({ path: "/certification" });
      } else {
        const windowReference = window.open("#");
        try {
          await http
            .get(`v1/doctors/certificate`)
            .then((res) => windowReference?.location.assign(res.data));
        } catch (err: any) {
          showErrors(err.response?.data);
        }
      }
    };

    return {
      t,
      addCert,
    };
  },
});
export default ProfileComponent;
