import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ced23842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusCircleFilled = _resolveComponent("PlusCircleFilled")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.t("PROFILE.HEADER")), 1 /* TEXT */),
    _createVNode(_component_a_card, { class: "profile__card" }, {
      default: _withCtx(() => [
        _createElementVNode("p", {
          innerHTML: _ctx.t('PROFILE.DESCRIPTION')
        }, null, 8 /* PROPS */, _hoisted_2),
        _createVNode(_component_a_button, {
          class: "profile__add-cert",
          shape: "round",
          type: "primary",
          size: "large",
          onClick: _ctx.addCert
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_PlusCircleFilled)
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.t("PROFILE.ADD_CERT")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}